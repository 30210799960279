css

.container {
  position: relative;
  z-index: 1;
}


.ComponentLogin{
  background-image: url('./assets/img/imageBackLogin.jpg') !important;
  /* n de fondo */
  background-size: cover !important; /* Ajustar la imagen de fondo al tamaño del contenedor */
  background-position: center !important; /* Centrar la imagen de fondo en el contenedor */

}

.card_login  {
  border-radius: 25px !important; 
}

.loader-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
}

button.btn.btn-primary.bg-color.mt-4.mr-2.btn.btn-primary {
  background-color: #f49f3c !important;
}

.react-datepicker-popper{
  z-index: 20 !important;
}

.disabled {
  opacity: 0.5; /* Reduce la opacidad para dar un efecto de difuminado */
  pointer-events: none; /* Deshabilita la interacción con el componente */
  background-color: rgba(211, 211, 211, 0.5);
}

