.ComponentLogin{
    background-image: url('../assets/img/imageBackLogin.jpg') !important;
    /* n de fondo */
    background-size: cover !important; /* Ajustar la imagen de fondo al tamaño del contenedor */
    background-position: center !important; /* Centrar la imagen de fondo en el contenedor */
    max-width: 100% !important;
  }

.page-sign{
    background-color: transparent !important;
}
